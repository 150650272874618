
import { Component, Vue, ProvideReactive } from "vue-property-decorator";
import store from "@/state";
import { DeliveryStatus, IOrder } from "@/state/orderStats";
import MarketItems from "@/components/MarketItems.vue";
import { get, patch } from "@/api/axios";

const orderState = store.modules.orderModule;

@Component({
    components: {
        "MarketItems": MarketItems
    }
})
export default class MarketOrderView extends Vue {
    state = orderState;
    isEditMode: boolean = false;
    selectorItems = ["created", "in_progress" , "failed" , "completed"];

    @ProvideReactive()
    items: IOrder[] = [];
    async mounted() {
        this.items = await this.state.getters.get_orders();

        console.warn(this.items);
    }

    editableOrderStatus: DeliveryStatus = "created";
    selectedOrderId: string = "";

    editOrder(id: string) {
        this.isEditMode = true;
        const order = this.items.find(x => x._id === id);
        this.editableOrderStatus = order!.deliveryStatus;
        this.selectedOrderId = id;
    }

    async saveOrder() {
        const order = this.items.find(x => x._id === this.selectedOrderId);
        if (!order)
        {
            console.error("bullshit happened, selected order not found in internal memory.");
            this.isEditMode = false;
            return;
        }
        await patch(`/api/@market/order/${this.selectedOrderId}/status/${this.editableOrderStatus}`, {});
        order!.deliveryStatus = this.editableOrderStatus;
        this.isEditMode = false;
    }
    cancelOrder(id: string) { }
    async goToUser(userID: string) {
        let detailed = await get(`/user/${userID}/detailed`);
        console.log(detailed);
        this.$router.push(`/employee/${detailed.data.employee._id}`);
    }
}
