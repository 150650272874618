
import { Component, Vue, ProvideReactive } from "vue-property-decorator";
import store from "@/state";
import MarketItems from "@/components/MarketItems.vue";
import { MarketItem } from "@/state/marketState";
import { patch, post } from "@/api/axios";

const marketState = store.modules.marketModule;

@Component({
    components: {
        "MarketItems": MarketItems
    }
})
export default class MarketItemView extends Vue {
    state = marketState;
    isAddMode = false;
    isEditMode = false;
    @ProvideReactive()
    items: MarketItem[] = [];
    async mounted() {
        await this.state.mutations.collectItems();

        setTimeout(() => {
            this.items = this.state.getters.items!;
            this.items.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
        }, 250);
    }

    newItem: MarketItem = { description: "", imageUrl: "", price: 0, title: "" };
    editingItem: MarketItem = { description: "", imageUrl: "", price: 0, title: "" };


    addNewItem() {
        this.isAddMode = true;
    }

    editItem(id: string) {
        let result = this.items.find(x => x._id == id);
        this.editingItem = result!;
        this.isEditMode = true;
    }

    async completeAddItem() {
        post(`/api/@market/`, this.newItem).then(x => {
            this.isAddMode = false
            this.items.push(x.data);
            this.items.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
            this.$forceUpdate();
        }).catch(e => {
            console.error(e);
            this.isAddMode = false;
        });
    }

    async completeSaveItem() {
        patch(`/api/@market/`, this.editingItem).then(x => {
            this.isEditMode = false
            this.items = this.items.filter(x => x._id != this.editingItem._id);
            this.items.push(x.data);
            this.items.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
            this.$forceUpdate();
        }).catch(e => {
            console.error(e);
            this.isEditMode = false;
        });
    }
}
