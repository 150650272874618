import Vue, { Component } from "vue";
import { render, Node } from "posthtml-render";
export interface IView {
    body: Node[];
    key: string;
    dependencies: string[]
}

export interface IComponentStateContext {

}

export interface IComponentExecutionContext {

}


function create_context_function_template(src: string, context: Vue) {
    return `
    return function (context) {
      "use strict";
      ${Object.keys(context).length > 0
            ? `let ${Object.keys(context).map((key) => ` ${key} = context['${key}']`)};`
            : ``
        }
      return ${src};
    }                                                                                                                   
    `
}

function make_context_evaluator(src: string, context: Vue) {
    let template = create_context_function_template(src, context)
    let functor = Function(template);
    return functor()
}


export function isolationEval(code: string, context: Vue) {
    const isolator = (function (ctx: Vue) {
        var $ctx = ctx;
        var window = null, document = null, history = null,
            screen = null, navigator = null, location = null;

        eval.call($ctx, code);

    });
    isolator.call(context, context);
}


function collectDeps(deps: string[]): Component[] {
    throw ""; // maybe not needed collect external components
}

export function importVueComponent(view: IView): Component {
    console.log(`Importing component '${view.key}'`, view.body);
    return {
        template: render(view.body)
    };
}