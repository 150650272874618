
import { Component, Vue, ProvideReactive, Watch } from "vue-property-decorator";
import { IOrder } from "@/state/orderStats";
import MarketItems from "@/components/MarketItems.vue";
import { get, post } from "@/api/axios";

interface IMarketTransaction {
    _id?: string;
    amount: number;
    user_ref: string;
    order_ref?: string;
    direction: "add" | "sub" | "canceled";
    title: string;
    description?: string;
}

@Component({
    components: {
        "MarketItems": MarketItems
    }
})
export default class MarketTransactions extends Vue {
    @ProvideReactive()
    txs: IMarketTransaction[] = [];
    @ProvideReactive()
    orders: IOrder[] = [];
    openedTransaction: number | undefined = -1;
    isAddMode: boolean = false;

    editingItem: IMarketTransaction = { amount: 0, direction: "add", title: "", user_ref: "" }

    async mounted() {
        await this.fetch();
    }


    async fetch() {
        this.txs = [];
        this.orders = [];

        let result = await get("/api/@market/transaction");

        this.txs = result.data;
        this.orders = new Array(this.txs.length);

        console.warn(this.txs);
    }

    async goToUser(userID: string) {
        let detailed = await get(`/user/${userID}/detailed`);
        console.log(detailed);
        this.$router.push(`/employee/${detailed.data.employee._id}`);
    }

    addNewTransaction() {
        this.isAddMode = true;
    }

    @Watch("openedTransaction")
    async fetchOrderDetails() {
        if (!this.openedTransaction)
            return;

        let tx = this.txs.at(this.openedTransaction);

        if (!tx!.order_ref)
            return;

        let order = await get(`/api/@market/order/${tx!.order_ref}`);

        this.orders[this.openedTransaction] = order.data;

        this.$forceUpdate();
    }
    cancelTransaction(tx: IMarketTransaction) {
        tx.direction = "canceled";
    }


    async completeAddTransaction() {
        await post(`/api/@market/transaction`, this.editingItem);
        this.isAddMode = false;
        await this.fetch();
        this.$forceUpdate();
    }


    getStyleByTx(tx: IMarketTransaction) {
        if (tx.direction == "add")
            return "color: yellowgreen";
        if (tx.direction == "canceled")
            return "color: orange";
        if (tx.direction == "sub")
            return "color: red";
        return "";
    }

    // === for user search ===
    items: { text: string, value: string }[] = [];
    loading: boolean = false;
    search: string | null = null;
    select: string | null = null;
    selectError: string | null = null;

    @Watch("search")
    forSearch(val: string) {
        val && val !== this.select && this.querySelections(val)
    }
    @Watch("select")
    forSelect(val: string) {
        console.log("ForSelect", val);
        this.validatorForLinks(val).then(result => {
            if (!result.valid) this.selectError = "Selected employeer is not linked to User Account!";
            else {
                this.editingItem.user_ref = (result.value as any)!.user._id;
                this.selectError = null;
            }
        });
    }

    async validatorForLinks(id: string) {
        let result = await get(`/employee/${id}/bound`);
        console.log(result.data);
        if (result.data) return { valid: true, value: result.data };
        return {valid: false, value: null};
    }

    async querySelections(val: string) {
        this.loading = true;
        

        let result = await get(`/api/engine/search/employee?query=${val}`);

        this.items = result.data;

        this.loading = false;
    }
}
