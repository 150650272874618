
import { Component, Vue } from "vue-property-decorator";
import MarketItemView from "@/views/market/MarketItemView.vue";
import MarketOrderView from "@/views/market/MarketOrderView.vue";
import MarketTransactions from "@/views/market/MarketTransactionsView.vue";
import GenericView from "@/views/GenericView.vue";
@Component({
  components: {
    "MarketItemView": MarketItemView,
    "MarketOrderView": MarketOrderView,
    "MarketTransactions": MarketTransactions,
    "generic-view": GenericView
  }
})
export default class MarketView extends Vue {
  tab: string = "tab-items";
}
