
import { Component as VueComponent } from "vue";
import { get } from "@/api/axios";
import { Component, Prop, Vue } from "vue-property-decorator";
import { importVueComponent, IView } from "@/api/render";


@Component
export default class GenericComponent extends Vue {
    @Prop()
    viewId!: string;
    @Prop()
    moduleId!: string;


    isLoaded: boolean = false;
    genericDef?: VueComponent;
    view?: IView;


    async mounted() {
        console.log("test")
        const result = await get<IView>(`/api/engine/view/${this.moduleId}/${this.viewId}`);
        this.view = result.data;
        this.genericDef = importVueComponent(this.view)!;
        this.isLoaded = true;
    }
}
