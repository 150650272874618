
import state from "@/state";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class MarketItems extends Vue {
  @Prop({type: String})
  public id!: string;
  @Prop({type: String})
  public title!: string;
  @Prop({type: String})
  public desc!: string;
  @Prop({type: String})
  public imageUrl!: string;
  @Prop({type: Number})
  public price!: number;
  @Prop({type: Boolean, default: false})
  public disableEdit!: boolean;

  @Prop({type: Function, default: () => {}})
  public onEdit!: Function;



  isLocked: boolean = true;

  editItem() {
    this.onEdit(this.id);
  }
}
